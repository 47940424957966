
    const css = `* {
  font-family: regular;
}
#modal {
  z-index: 11;
  background-color: #d9d9d9;
  border: none;
  border-radius: 5px;
  width: 20%;
}
#createProject {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
svg {
  transform: scale(1.3);
}
.projectsSection {
  margin: 0 0 0 2rem;
  display: flex;
  align-items: center;
}
select {
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}
button {
  height: 30px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.modalDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
}
input {
  height: 30px;
  border: none;
  border-radius: 5px;
}
form button {
  margin: 10px 5px 0 5px;
}
p {
  margin: 0 0 10px 0;
}`;
    export default css;
  