import {
  addDoc,
  collection,
  db,
  doc,
  getDoc,
  getDocs,
} from "../../firebase/firebaseConfig.js";
import { throwSnackbar } from "../services/snackBar.js";
import css from "./projectsOverviewCSS.js";
export class projectsOverview extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
    this.userRole = JSON.parse(localStorage.getItem("selectedProjectUserRole"));
    this.root.innerHTML = `
    <div class="projectsSection">
    <label for="projects-select">Projekte</label>
    <select name="projects" id="projects-select">
      <option value="">Wählen sie ein Projekt aus</option>
    </select>
    ${
      this.userRole != "view"
        ? `<button id="createProject">
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
    </button>`
        : ""
    }
  </div>
  <dialog id="modal">
  <div class="modalDiv">
  <p>
  Neues Projekt erstellen
  </p>
  <form>
  <div>
  <label for="projectName">Projektname</label>
  <input id="projectName" type="text" placeholder="Projektname" required>
  </div>
  <div class ="buttonWrapper">
  <button id="cancelButton" type="button">Abbrechen</button>
  <button id="submitNewProject">Bestätigen</button>
  </div>
</form>
</div>
</dialog>
    `;
    const style = document.createElement("style");
    this.root.appendChild(style);
    async function loadCSS() {
      style.textContent = css;
    }
    const userLocal = JSON.parse(localStorage.getItem("user"));
    this.userIdLocal = userLocal.uid;
    loadCSS();
  }
  connectedCallback() {
    this.render();
    this.getProjects();
    window.addEventListener("click", (event) => {
      const dialogElement = this.root.getElementById("modal");
      dialogElement.getAttribute("open");
    });

    const createButton = this.root.getElementById("createProject");
    const dialogElement = this.root.getElementById("modal");
    createButton.addEventListener("click", (event) => {
      dialogElement.setAttribute("open", true);
      event.stopPropagation();
    });
  }

  render() {
    const dialogElement = this.root.getElementById("modal");
    const submitButtonProject = this.root.getElementById("submitNewProject");
    const inputValue = this.root.getElementById("projectName");
    submitButtonProject.onclick = (event) => {
      event.preventDefault();
      const projectName = inputValue.value;
      this.createNewProject(projectName);
      const dialogElement = this.root.getElementById("modal");
      dialogElement.removeAttribute("open");
    };
    const cancelButton = this.root.getElementById("cancelButton");
    cancelButton.onclick = () => {
      dialogElement.removeAttribute("open");
    };
  }

  async getProjects() {
    const uid = JSON.parse(localStorage.getItem("user") || "{}").uid;
    const projectsCollection = collection(db, "projects");

    try {
      const querySnapshot = await getDocs(projectsCollection);
      let projects = [];

      querySnapshot.forEach((doc) => {
        const project = doc.data();
        project.id = doc.id;
        if (project.users && Array.isArray(project.users)) {
          const foundUser = project.users.find((user) => user.uid === uid);
          if (foundUser) {
            projects.push(project);
          }
        }
      });

      this.renderProjectsOptions(projects);
      return projects;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  renderProjectsOptions(projectsData) {
    const projectSelectElement = this.root.getElementById("projects-select");
    let selectedProjectId;

    try {
      selectedProjectId = JSON.parse(localStorage.getItem("selectedProject"));
      if (selectedProjectId && selectedProjectId.length > 2) {
        window.dispatchEvent(
          new CustomEvent("projectSelected", { detail: selectedProjectId })
        );
      }
    } catch (error) {}

    projectSelectElement.addEventListener("change", async (event) => {
      window.dispatchEvent(
        new CustomEvent("projectSelected", { detail: event.target.value })
      );

      localStorage.setItem(
        "selectedProject",
        JSON.stringify(event.target.value)
      );
      const users = await getAlluser(event.target.value);
      const userMe = users.find((user) => user.uid === this.userIdLocal);
      const localRole = userMe.role;
      async function getAlluser(projectId) {
        const projectDocRef = doc(db, "projects", projectId);
        try {
          const projectDocSnapshot = await getDoc(projectDocRef);
          if (projectDocSnapshot.exists()) {
            const userField = projectDocSnapshot.get("users");
            return userField;
          } else {
          }
        } catch (error) {}
      }
      localStorage.setItem(
        "selectedProjectUserRole",
        JSON.stringify(localRole)
      );
      window.dispatchEvent(new CustomEvent("newProjectSetting"));
    });
    const selectedProjectOptionExists = Array.from(
      projectSelectElement.options
    ).some((option) => option.value === selectedProjectId);
    if (selectedProjectId && !selectedProjectOptionExists) {
      const selectedProject = projectsData.find(
        (project) => project.id === selectedProjectId
      );
      if (selectedProject) {
        const selectedProjectOption = document.createElement("option");
        selectedProjectOption.textContent = selectedProject.title;
        selectedProjectOption.value = selectedProject.id;
        selectedProjectOption.selected = true;
        projectSelectElement.appendChild(selectedProjectOption);
      }
    }
    projectsData.forEach((project) => {
      if (project.id !== selectedProjectId) {
        const optionElement = document.createElement("option");
        optionElement.textContent = project.title;
        optionElement.value = project.id;
        projectSelectElement.appendChild(optionElement);
      }
    });
  }

  async createNewProject(projectName) {
    try {
      // Add a new document with a generated id.
      const uid = JSON.parse(localStorage.getItem("user") || "{}").uid;
      const docRef = await addDoc(collection(db, "projects"), {
        title: projectName,
        // users: [uid],
        users: [
          {
            uid: uid,
            role: "admin",
          },
        ],
      });
      throwSnackbar("Projekt erfolgreich erstellt", "black", "green");
    } catch (error) {
      throwSnackbar("Projekt konnte nicht erstellt werden", "black", "red");
    }
  }
}

customElements.define("projects-overview", projectsOverview);
